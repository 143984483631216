import React, { useEffect, useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import styles from "./GroupedButtons.module.sass";

const GroupedButtons = (props) => {
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    props.updateMintCount(counter);
  });

  const handleIncrement = () => {
    const update = counter + 1;
    setCounter(update);
    props.updateMintCount(update);
  };

  const handleDecrement = () => {
    if (counter > 1) {
      const update = counter - 1;
      setCounter(update);
      props.updateMintCount(update);
    }
  };

  return (
    <>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button className={styles.minBtn} onClick={handleDecrement}>
          -
        </Button>
        {
          <Button className={styles.counterBtn} disabled>
            {counter}
          </Button>
        }
        {
          <Button className={styles.addBtn} onClick={handleIncrement}>
            +
          </Button>
        }
      </ButtonGroup>
    </>
  );
};

/*
class GroupedButtons extends React.Component {
  state = { counter: 0 };

  handleIncrement = () => {
    this.setState(state => ({ counter: state.counter + 1 }));
  };

  handleDecrement = () => {
    if (this.state.counter > 0) {  
        this.setState(state => ({ counter: state.counter - 1 }));
        console.log(this.state.counter)
        this.props.updateMintCount(this.state.counter)
    }
  };
  render() {
    const displayCounter = this.state.counter > 0;

    return (
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={this.handleIncrement}>+</Button>
        {<Button disabled>{this.state.counter}</Button>}
        {<Button onClick={this.handleDecrement}>-</Button>}
      </ButtonGroup>
    );
  }
}
*/

export default GroupedButtons;
