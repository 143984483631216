import React, { useEffect, useState, useCallback } from "react";
import cn from "classnames";
import GroupedButtons from "../../../../components/GroupedButtons";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./MintBox.module.sass";
import DiscordButton from "../../../../components/Discord";
import {} from "../../../../utils/interact";

const MintBox = ({
  className,
  totalMinted,
  maxMinted,
  canMint,
  mintCount,
  totalPrice,
  onClick,
  onWalletClick,
  updateMintCount,
  contractLink,
  walletAddress,
  network,
  token,
}) => {
  return (
    <div className={styles.wrap}>
      {/*<ProgressBar
        completed={(totalMinted / maxMinted) * 100}
        isLabelVisible={false}
        bgColor="#3772FF"
      />
      <div className={styles.info}>
        Total Minted: {totalMinted}/{maxMinted} 
      </div>*/}
      <div className={styles.currency}>
        {(totalPrice * mintCount).toFixed(2)} {token}
      </div>
      <div className={styles.price}>
        <GroupedButtons updateMintCount={updateMintCount} />
      </div>
      <div className={styles.maxmint}>{canMint} max mint</div>
      <div className={styles.btns}>
        <button
          disabled={mintCount <= canMint ? false : true}
          className={cn("button", styles.button)}
          onClick={walletAddress !== "" ? onClick : onWalletClick}
        >
          {walletAddress !== "" ? "Mint" : "Connect Wallet"}
        </button>
      </div>
      {/*<div className={styles.networkInfo}>
        Network: {network}
      </div>*/}
      {/*<div className={styles.contract}>
        <a href={contractLink} target="_blank">
          smart contract
        </a>
      </div>*/}
    </div>
  );
};

export default MintBox;
