import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Image from "../Image";
import Notification from "./Notification";
import User from "./User";
import { isRinkeby, /*getCurrentSale,*/ isPre } from "../../utils/interact.js";

const nav = [
  {
    url: "/search01",
    title: "Discover",
  },
  {
    url: "/profile",
    title: "Profile",
  },
];

const Headers = (props) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const [saleType, setSaleType] = useState("closed");

  useEffect(() => {
    (async function () {
      //setSaleType(await getCurrentSale())
    })();
  }, []);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <img
            src="/nifty-base-logo-180.png"
            alt="NiftyBase"
            width="64px"
            height="64px"
          />
          <h2 className={styles.title}>Niftybase</h2>
        </Link>
        <Link className={styles.logo} to="/trippy-sushi">
          {saleType !== "closed" ? <p className={styles.saleTitle}></p> : <></>}
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}></nav>
        </div>
        <div>
          <div className={cn("title", styles.address)}>
            {props.address
              ? "Connected: " +
                String(props.address).substring(0, 6) +
                "..." +
                String(props.address).substring(38)
              : ""}
          </div>
        </div>
        {/*<User className={styles.user} />*/}
        {/*
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>\
        */}
      </div>
    </header>
  );
};

export default Headers;
